import { createStore } from 'vuex'

import ecoCon1 from '@/assets/shop/images/ecoCon1.jpg'
import ecoCon2 from '@/assets/shop/images/ecoCon2.jpg'
import ecoCon3 from '@/assets/shop/images/ecoCon3.jpg'

import img_cart_1 from '@/assets/shop/images/mainCard1.png'
import img_cart_2 from '@/assets/shop/images/mainCard2.png'
import img_cart_3 from '@/assets/shop/images/mainCard3.png'

import Glass_Happy from "@/assets/game/cardsJSONS/Glass_Happy.json";
import Glass_Sad from "@/assets/game/cardsJSONS/Glass_Sad.json"
import Grocceries_Happy from "@/assets/game/cardsJSONS/Grocceries_Happy.json"
import Grocceries_Sad from "@/assets/game/cardsJSONS/Grocceries_Sad.json"
import Bottle_Happy from "@/assets/game/cardsJSONS/Bottle_Happy.json"
import Bottle_Sad from "@/assets/game/cardsJSONS/Bottle_Sad.json"
import Salad_Happy from "@/assets/game/cardsJSONS/Salad_Happy.json"
import Salad_Sad from "@/assets/game/cardsJSONS/Salad_Sad.json"
import Dispencer_Happy from "@/assets/game/cardsJSONS/Dispencer_Happy.json"
import Dispencer_Sad from "@/assets/game/cardsJSONS/Dispencer_Sad.json"
import Shirt_Happy from "@/assets/game/cardsJSONS/Shirt_Happy.json"
import Shirt_Sad from "@/assets/game/cardsJSONS/Shirt_Sad.json"
import Straw_Happy from "@/assets/game/cardsJSONS/Straw_Happy.json"
import Straw_Sad from "@/assets/game/cardsJSONS/Straw_Sad.json"
import Bag_Happy from "@/assets/game/cardsJSONS/Bag_Happy.json"
import Bag_Sad from "@/assets/game/cardsJSONS/Bag_Sad.json"
import Cooler_Happy from "@/assets/game/cardsJSONS/Cooler_Happy.json"
import Cooler_Sad from "@/assets/game/cardsJSONS/Cooler_Sad.json"
import Box_Happy from "@/assets/game/cardsJSONS/Box_Happy.json"
import Box_Sad from "@/assets/game/cardsJSONS/Box_Sad.json"
import Soap_Happy from "@/assets/game/cardsJSONS/Soap_Happy.json"
import Soap_Sad from "@/assets/game/cardsJSONS/Soap_Sad.json"
import Patch_Happy from "@/assets/game/cardsJSONS/Patch_Happy.json"
import Patch_Sad from "@/assets/game/cardsJSONS/Patch_Sad.json"

import Clothes from "@/assets/game/matchJSONS/clothes.json"
import Grocceries from "@/assets/game/matchJSONS/grocceries.json"
import lunch from "@/assets/game/matchJSONS/lunch.json"
import smoothie from "@/assets/game/matchJSONS/smoothie.json"
import soap from "@/assets/game/matchJSONS/soap.json"
import water from "@/assets/game/matchJSONS/water.json"




export default createStore({
  state: {
    animationMain: false,
    cards: [
      {
        id: 1,
        jsonHappy: Glass_Happy,
        jsonSad: Glass_Sad,
        jsonMatch: smoothie,
        background: '#BDD8CF',
        titleText: 'Выпьем за природу!',
        text: 'Наслаждайтесь напитками столько, сколько захотитe',
      },
      {
        id: 2,
        jsonHappy: Grocceries_Happy,
        jsonSad: Grocceries_Sad,
        jsonMatch: Grocceries,
        background: '#EDDE9D',
        titleText: 'Домой — с покупками!',
        text: 'Самый экологичный спутник для похода в магазин',
      },
      {
        id: 3,
        jsonHappy: Bottle_Happy,
        jsonSad: Bottle_Sad,
        jsonMatch: water,
        background: '#94c2d2',
        titleText: 'Всегда с собой',
        text: 'Легко налить свежую воду. Легко обойтись без пластика',
      },
      {
        id: 4,
        jsonHappy: Salad_Happy,
        jsonSad: Salad_Sad,
        jsonMatch: lunch,
        background: '#F0BA8E',
        titleText: 'Ммм, ланч!',
        text: 'Когда любимый перекус всегда с собой — без одноразовой посуды',
      },
      {
        id: 5,
        jsonHappy: Dispencer_Happy,
        jsonSad: Dispencer_Sad,
        jsonMatch: soap,
        background: '#ff476f',
        fillMode: true,
        titleText: 'Созданы быть вместе',
        text: 'Многоразовые ёмкости и капсулы с экоконцентратом отлично пополняют и дополняют друг друга!'
      },
      {
        id: 6,
        jsonHappy: Shirt_Happy,
        jsonSad: Shirt_Sad,
        jsonMatch: Clothes,
        background: '#9E97A9',
        titleText: 'Любимые вещи',
        text: 'Небольшой ремонт — и они как новые!',
      },
      {
        id: 1,
        jsonHappy: Straw_Happy,
        jsonSad: Straw_Sad,
        jsonMatch: smoothie,
        background: '#BDD8CF',
        titleText: 'Выпьем за природу!',
        text: 'Наслаждайтесь напитками столько, сколько захотите',
      },
      {
        id: 2,
        jsonHappy: Bag_Happy,
        jsonSad: Bag_Sad,
        jsonMatch: Grocceries,
        background: '#EDDE9D',
        titleText: 'Домой — с покупками!',
        text: 'Самый экологичный спутник для похода в магазин',
      },
      {
        id: 3,
        jsonHappy: Cooler_Happy,
        jsonSad: Cooler_Sad,
        jsonMatch: water,
        background: '#94c2d2',
        titleText: 'Всегда с собой',
        text: 'Легко налить свежую воду. Легко обойтись без пластика',
      },
      {
        id: 4,
        jsonHappy: Box_Happy,
        jsonSad: Box_Sad,
        jsonMatch: lunch,
        background: '#F0BA8E',
        titleText: 'Ммм, ланч!',
        text: 'Когда любимый перекус всегда с собой — без одноразовой посуды',

      },
      {
        id: 5,
        jsonHappy: Soap_Happy,
        jsonSad: Soap_Sad,
        jsonMatch: soap,
        background: '#ff476f',
        fillMode: true,
        titleText: 'Созданы быть вместе',
        text: 'Многоразовые ёмкости и капсулы с экоконцентратом отлично пополняют и дополняют друг друга!'

      },
      {
        id: 6,
        jsonHappy: Patch_Happy,
        jsonSad: Patch_Sad,
        jsonMatch: Clothes,
        background: '#9E97A9',
        titleText: 'Любимые вещи',
        text: 'Небольшой ремонт — и они как новые!',
      }
    ],
    //.sort(()=>Math.random()-0.5),
    mainCards: [
      {
        image:img_cart_1,
        image2: ecoCon2,
        title: 'Экоочиститель любых поверхностей и сменная капсула',
        title2: 'Экоочиститель любых поверхностей',
        value: '500+50 мл',
        value2: '2x50 мл',
        text: 'Отлично удаляет загрязнение с любых поверхностей в доме. Также\n' +
            '              подходит для очистки обуви, изделий из кожи и ткани. Не портит\n' +
            '              поверхность и не оставляет следов.'
      },
      {
        image: img_cart_2,
        image2: ecoCon1,
        title: 'Экосредство для мытья пола и сменная капсула',
        title2: 'Экосредство для мытья пола',
        value: '500+50 мл',
        value2: '2x50 мл',
        text: 'Подходит для мытья любого пола: от камня до линолеума. Не оставляет белого налёта и не раздражает едким запахом. Безопасно для домашних животных и детей.'
      },
      {
        image: img_cart_3,
        image2: ecoCon3,
        title: 'Экопенка для мытья посуды и сменная капсула',
        title2: 'Экопенка для мытья посуды',
        value: '500+50 мл',
        value2: '2x50 мл',
        text: 'Гипоаллергенное средство с приятным запахом и антибактериальным эффектом. Отлично пенится. Действует даже в холодной воде и легко смывается.'
      },

    ],

  },
  getters: {

  },
  mutations: {
    setAnimationMain(state, val){
      state.animationMain = val
    }
  },
  actions: {
  },
  modules: {
  }
})
