import { createRouter, createWebHistory } from 'vue-router'
const routes = [
  {
    path: '',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/game',
    name: 'game',
    component: () => import('../views/GameView.vue')
  },
  {
    path: '/shop',
    name: 'shop',
    component: () => import('../views/ShopView.vue')
  },
  //   {
  //   path: '/test',
  //   name: 'test',
  //   component: () => import('../views/test.vue')
  // },
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: () => import('../views/HomeView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
