<template>
    <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
            <component :is="Component" />
        </transition>
    </router-view>
</template>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.35s ease;
}

.fade-enter-from,
.fade-leave-active {
    opacity: 0;
}
</style>

<style lang="scss">
@import "normalize.css";
@import "@/style/root.css";

@font-face {
    font-family: "Euclid Circular A";
    src: url("@/assets/fonts/EuclidCircularA-Bold.otf") format("opentype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Euclid Circular A";
    src: url("@/assets/fonts/EuclidCircularA-Medium.otf") format("opentype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Euclid Circular A";
    src: url("@/assets/fonts/EuclidCircularA-Regular.otf") format("opentype");
    font-weight: 400;
    font-style: normal;
}

body {
    font-family: "Euclid Circular A", sans-serif;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    overflow-x: hidden;
}
</style>
